.project {
    
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.project h1 {
    margin-top: 50px;
    color: #3e497a;
    font-size: 35px;
}

.project img {
    height : 400px;
    width: 700px;
    border-radius: 10px;
}

.project p {
    padding: 15px;
    font-size: 30px;
    color: #3e497a;
}
.project li{
    font-size: 20px;
    color: #3e497a;
    padding: 5px;
}

.project svg {
    font-size: 60px;
    color: #3e497a;
}
@media only screen and (max-width: 600px) {
    .project h1 {
    font-size: 30px;
    padding: 15px;
    text-align: center;
    }

    .project img {
    height : 200px;
    width: 300px;
    border-radius: 5px;
    }

    .project p {
        font-size: 15px;
        
        padding: 15px;
    }

    .project li {
        font-size: 10px;
        padding: 5px;
        
    }
    .project svg {
        padding: 30px;
        font-size: 30px;
        
    }
}